import { type IStyleParam, type StyleParamType } from '@wix/tpa-settings';

type GetStyleParamType = {
  isMobile: boolean;
  getStyleParamValue: (param: IStyleParam) => any;
  paramName: IStyleParam;
  mobileParamName: IStyleParam;
  dependencyParam: IStyleParam;
};
/**
 * Helps to move away from using separate style params for mobile and desktop.
 *
 * Resolves the default value based on legacy style params created with `createStylesParam()`.
 *
 * @example
 * export default createStylesParams({
 *   showNewestPostsFirstV2: {
 *     type: StyleParamType.Boolean,
 *     getDefaultValue: ({ isMobile, getStyleParamValue }) =>
 *       resolveDefaultStyleParamValue({
 *         isMobile,
 *         getStyleParamValue,
 *         paramName: LEGACY_STYLE_PARAMS.showNewestPostsFirst,
 *         mobileParamName: LEGACY_STYLE_PARAMS.showNewestPostsFirstMobile,
 *         dependencyParam: LEGACY_STYLE_PARAMS.isMobileDisplaySettingsEnabled,
 *       }),
 *   },
 * });
 */
export const resolveDefaultStyleParamValue = ({
  isMobile,
  getStyleParamValue,
  paramName,
  mobileParamName,
  dependencyParam,
}: GetStyleParamType) => {
  if (isMobile) {
    const isMobileSettingsEnabled = getStyleParamValue(dependencyParam);
    if (isMobileSettingsEnabled) {
      const mobileParamValue = getStyleParamValue(mobileParamName);
      if (typeof mobileParamValue !== 'undefined') {
        return mobileParamValue;
      }
    }
  }
  return getStyleParamValue(paramName);
};

const enum AlignmentValue {
  Start = 0,
  Center = 1,
  End = 2,
}

export const getFlexAlignmentValue = (alignment: AlignmentValue, isRTL: boolean) => {
  const alignmentMap = {
    [AlignmentValue.Start]: isRTL ? 'flex-end' : 'flex-start',
    [AlignmentValue.Center]: 'center',
    [AlignmentValue.End]: isRTL ? 'flex-start' : 'flex-end',
  } as const satisfies Record<number, string>;

  return alignmentMap[alignment];
};

export const getTextAlignmentValue = (alignment: AlignmentValue, isRTL: boolean) => {
  const alignmentMap = {
    [AlignmentValue.Start]: isRTL ? 'end' : 'start',
    [AlignmentValue.Center]: 'center',
    [AlignmentValue.End]: isRTL ? 'start' : 'end',
  } as const satisfies Record<number, string>;

  return alignmentMap[alignment];
};

export function getFontCssValue(
  fontParamValue: FontValue,
  property: 'font',
): Extract<React.CSSProperties['font'], string>;
export function getFontCssValue(
  fontParamValue: FontValue,
  property: 'textDecoration',
): Extract<React.CSSProperties['textDecoration'], string>;
export function getFontCssValue(
  fontParamValue: FontValue,
  property: 'font' | 'textDecoration',
): Extract<React.CSSProperties['font'] | React.CSSProperties['textDecoration'], string> {
  if (property === 'textDecoration') {
    return fontParamValue.underline ? 'underline' : 'none';
  }

  const fontFamily = fontParamValue.family.join(', ');
  const fontLineHeight = fontParamValue.lineHeight ?? 1.5;
  const fontSize = fontParamValue.size ?? 16;
  const fontStyle = fontParamValue.style ?? 'normal';
  const fontWeight = fontParamValue.weight ?? 'normal';
  const fontVariant = fontParamValue.variant ?? 'normal';

  return `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}/${fontLineHeight} ${fontFamily}`;
}

type FontValue = {
  lineHeight: string;
  stretch: string;
  style: string;
  variant: string;
  weight: string;
  size: string;
  family: string[];
  underline: boolean;
  supports: {
    uppercase: boolean;
  };
};

/**
 * Allows to derive style param values based on style param definitions
 *
 * @example
 * type StyleParams = {
 *  showAllPostsCategory: StyleParamType.Boolean;
 *  numberOfPosts: StyleParamType.Number;
 * }
 *
 * createStyleParams<StyleParams>({ ... });
 *
 * type StyleParamsValues = StyleParamsValues<StyleParams>;
 * // ^ type
 * // {
 * //   numbers: { numberOfPosts: number },
 * //   booleans: { showAllPostsCategory: boolean; }
 * // }
 */
export type StyleParamsValues<SP extends Record<string, StyleParamType>> = {
  numbers: {
    [K in keyof SP as SP[K] extends StyleParamType.Number ? K : never]: number;
  };
  booleans: {
    [K in keyof SP as SP[K] extends StyleParamType.Boolean ? K : never]: boolean;
  };
  colors: {
    [K in keyof SP as SP[K] extends StyleParamType.Color ? K : never]: string;
  };
  fonts: {
    [K in keyof SP as SP[K] extends StyleParamType.Font ? K : never]: FontValue;
  };
  strings: {
    [K in keyof SP as SP[K] extends StyleParamType.String ? K : never]: string;
  };
};

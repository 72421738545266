import React, { Suspense, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useSlotPlaceholder } from '@wix/widget-plugins-ooi';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { resolveId } from '@wix/communities-blog-client-common';
import {
  ABOVE_CONTENT_1,
  ABOVE_CONTENT_2,
  PAGE_BOTTOM_1,
  PAGE_BOTTOM_2,
  PAGE_BOTTOM_3,
} from '@app/constants/ooi-slots';
import AnimatedLoader from '@app/external/common/components/animated-loader';
import Post from '@app/external/common/components/post';
import PrintWrapper from '@app/external/common/components/print-wrapper';
import { useActions, useSelector } from '@app/external/common/components/runtime-context';
import useFontClassName from '@app/external/common/hooks/use-font-class-name';
import useOnScreen from '@app/external/common/hooks/use-on-screen';
import { usePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';
import { getRoute } from '@app/external/common/router/router-selectors';
import { getPostByIdOrSlug } from '@app/external/common/selectors/post-selectors';
import { isInPostPreview } from '@app/external/common/services/detect-route';
import scrollToContent from '@app/external/common/services/scroll-to-content';
import { resolvePostSlug } from '@app/external/common/services/slug';
import {
  getCommentId,
  isSeo as getIsSeo,
} from '@app/external/common/store/basic-params/basic-params-selectors';
import { getIsPostLoaded } from '@app/external/common/store/is-loaded/is-loaded-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import PostPageLayout from '../../components/post-page-layout';
import ReadingTimeListener, {
  type OnScrollHandler,
  type OnTabVisibilityChangeHandler,
} from '../../components/reading-time-listener/use-reading-time-listener';
import { type RoutePostParams } from '../../constants/routes';
import { getReadingSessionId } from '../../selectors/reading-session-id-selector';
import { PostPageWixComments } from './post-page-wix-comments-safe';
import { PostPageWixCommentsSeo } from './post-page-wix-comments-with-seo';
import RelevantPosts from './relevant-posts';
import styles from './post-page.scss';

type Props = {
  params: RoutePostParams;
};

const PostPage = (props: Props) => {
  const { post, postSlug, readingSessionId, isPostLoaded } = usePostPageSlice(props);
  const { isSSR, isEditor } = useEnvironment();
  const { showComments } = usePostPageSettings({ post });
  const { contentFontClassName } = useFontClassName();
  const actions = useActions();
  const [forceComments, setForceComments] = React.useState(false);
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const [AboveContent1SlotsPlaceholder, isAboveContent1SlotEmpty] =
    useSlotPlaceholder(ABOVE_CONTENT_1);
  const [AboveContent2SlotsPlaceholder, isAboveContent2SlotEmpty] =
    useSlotPlaceholder(ABOVE_CONTENT_2);
  const [PageBottom1SlotsPlaceholder, isPageBottom1SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_1);
  const [PageBottom2SlotsPlaceholder, isPageBottom2SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_2);
  const [PageBottom3SlotsPlaceholder, isPageBottom3SlotEmpty] = useSlotPlaceholder(PAGE_BOTTOM_3);

  const biElement = useBiElement({
    post,
    biActiveTabChanged: actions.biActiveTabChanged,
    biPostScrolled: actions.biPostScrolled,
    readingSessionId,
    slug: postSlug,
    trackEvent: actions.trackEvent,
    onSlugChange: () => {
      scrollToContent(styles.postPage);
    },
  });

  return (
    <PostPageLayout
      className={classNames(styles.postPage, contentFontClassName)}
      data-hook="post-page"
    >
      {!isSSR && !isEditor && biElement}
      <div className={!isAboveContent1SlotEmpty ? styles.aboveContentSlot : ''}>
        <AboveContent1SlotsPlaceholder />
      </div>
      <div className={!isAboveContent2SlotEmpty ? styles.aboveContentSlot : ''}>
        <AboveContent2SlotsPlaceholder />
      </div>
      <div className={styles.wrapper}>
        <AnimatedLoader isLoading={!isPostLoaded && !post}>
          <div className={styles.post}>
            <PrintWrapper>
              <Post
                key={resolveId(post)}
                post={post}
                isInPostPage
                onRatingsDisplayClick={() => {
                  if (!forceComments) {
                    setForceComments(true);
                  }

                  requestAnimationFrame(() => {
                    commentsRef.current?.scrollIntoView({
                      block: 'start',
                      behavior: 'smooth',
                    });
                  });
                }}
              />
            </PrintWrapper>
          </div>
          {!isPageBottom1SlotEmpty && (
            <div className={classNames(styles.pageBottomSlot, styles.mobileContainer)}>
              <PageBottom1SlotsPlaceholder />
            </div>
          )}
          <RelevantPosts post={post} />
          {!isPageBottom2SlotEmpty && (
            <div className={classNames(styles.pageBottomSlot, styles.mobileContainer)}>
              <PageBottom2SlotsPlaceholder />
            </div>
          )}
          <CommentsSection
            params={props.params}
            commentsRef={commentsRef}
            forceComments={forceComments}
            showComments={showComments}
          />
          {!isPageBottom3SlotEmpty && (
            <div className={classNames(styles.pageBottomSlot, styles.mobileContainer)}>
              <PageBottom3SlotsPlaceholder />
            </div>
          )}
        </AnimatedLoader>
      </div>
    </PostPageLayout>
  );
};

type CommentsSectionProps = React.FC<
  React.ComponentProps<typeof PostPage> & {
    showComments: boolean;
    forceComments: boolean;
    commentsRef: React.RefObject<HTMLDivElement>;
  }
>;

const CommentsSection: CommentsSectionProps = (props) => {
  const { post, isSeo, commentId } = useCommentsSectionSlice(props);
  const { isSSR, isEditor } = useEnvironment();
  const canSeePost = post?.canSeePaidContent !== false;
  const renderComments = canSeePost && props.showComments && post?.id;
  const { isOnScreen, ref } = useOnScreen(undefined, isEditor);
  const actions = useActions();

  if (!renderComments) {
    return null;
  }

  if (isSeo) {
    return (
      <div ref={props.commentsRef}>
        <PostPageWixCommentsSeo post={post} />
      </div>
    );
  }

  if (!isSSR && commentId) {
    actions.initWixCommentsController();

    return (
      <div ref={props.commentsRef}>
        <Suspense fallback={<AnimatedLoader isLoading />}>
          <PostPageWixComments post={post} />
        </Suspense>
      </div>
    );
  }

  if (!isOnScreen && !props.forceComments) {
    return <div ref={ref as any} />;
  }

  actions.initWixCommentsController();

  return (
    <div ref={props.commentsRef}>
      <Suspense fallback={<AnimatedLoader isLoading />}>
        <PostPageWixComments post={post} />
      </Suspense>
    </div>
  );
};

type PostPageBiParams = {
  biActiveTabChanged: (payload: any) => void;
  biPostScrolled: (payload: any) => void;
  post?: NormalizedPost;
  readingSessionId: string;
  slug?: string;
  trackEvent: (eventName: string, params: any) => void;
  onSlugChange: () => void;
};

const useBiElement = ({
  biActiveTabChanged,
  biPostScrolled,
  onSlugChange,
  post,
  readingSessionId,
  slug,
  trackEvent,
}: PostPageBiParams) => {
  const lastSlugRef = useRef(slug);
  const slugOnChangeRef = useRef(onSlugChange);

  const blogPostViewTrackEvent = useCallback(() => {
    trackEvent('BlogPostView', {
      event: 'BlogPostView',
      eventCategory: 'Wix Blog',
      eventAction: 'BlogPostView',
      eventLabel: post?.title,
      origin: 'Wix Blog',
    });
  }, [post?.title, trackEvent]);

  useEffect(() => {
    blogPostViewTrackEvent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastSlugRef.current !== slug && slug) {
      lastSlugRef.current = slug;
      blogPostViewTrackEvent();
      slugOnChangeRef.current();
    }
  }, [slug, blogPostViewTrackEvent]);

  const handleScroll: OnScrollHandler = useCallback(
    (event) => {
      biPostScrolled({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biPostScrolled],
  );

  const handleTabVisibilityChange: OnTabVisibilityChangeHandler = useCallback(
    (event) => {
      biActiveTabChanged({
        post_stable_id: post?.id,
        is_demo: post?.isDemo,
        ...event,
      });
    },
    [post?.id, post?.isDemo, biActiveTabChanged],
  );

  return (
    <ReadingTimeListener
      getContentContainer={getPostContentContainer}
      onScroll={handleScroll}
      onTabVisibilityChange={handleTabVisibilityChange}
      readingSessionId={readingSessionId}
    />
  );
};

const getPostContentContainer = () => {
  return document.querySelector('[data-hook="post"]')?.getBoundingClientRect();
};

const usePostPageSlice = (ownProps: Props) => {
  return useSelector((state) => {
    const postSlug =
      'postId' in ownProps.params ? ownProps.params.postId : resolvePostSlug(ownProps.params);

    const useDraft = isInPostPreview(getRoute(state));
    let post = getPostByIdOrSlug(state, postSlug)!;

    post = useDraft ? { ...post, ...(post?.draft || {}) } : post;

    return {
      post,
      postSlug,
      isPostLoaded: getIsPostLoaded(state, postSlug),
      readingSessionId: getReadingSessionId(state),
    };
  });
};

const useCommentsSectionSlice = (ownProps: Props) => {
  return useSelector((state) => {
    const postSlug =
      'postId' in ownProps.params ? ownProps.params.postId : resolvePostSlug(ownProps.params);
    const useDraft = isInPostPreview(getRoute(state));
    let post = getPostByIdOrSlug(state, postSlug)!;

    post = useDraft ? { ...post, ...(post?.draft || {}) } : post;

    return {
      post,
      isSeo: getIsSeo(state),
      commentId: getCommentId(state),
    };
  });
};

export default PostPage;

import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import {
  getIsAppBundle,
  getIsPostPageBundle,
  SECTION_CATEGORY,
} from '@wix/communities-blog-client-common';
import { TooltipPortalProvider } from '../../context/tooltip-context';
import useBlogMenuSettings from '../../hooks/use-blog-menu-settings';
import { getIsCreatedWithResponsiveEditor } from '../../selectors/app-settings-selectors';
import { getSection } from '../../selectors/section-selectors';
import AppWrapper from '../app-wrapper';
import { useSelector } from '../runtime-context';
import styles from './app.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  desktopHeader?: React.FunctionComponentElement<{
    menu?: React.FunctionComponentElement<any>;
  }>;
  main?: React.FunctionComponentElement<{ children?: React.ReactNode }>;
  menu?: React.FunctionComponentElement<any>;
  messageRoot?: React.FunctionComponentElement<any>;
  modalRoot?: React.FunctionComponentElement<any>;
  popoverRoot?: React.FunctionComponentElement<any>;
}>;

const AppDesktop: React.FC<Props> = ({
  children,
  className,
  desktopHeader,
  main,
  menu,
  messageRoot,
  modalRoot,
  popoverRoot,
}) => {
  const { isEditor, isPreview } = useEnvironment();
  const isPostPageBundle = useSelector(getIsPostPageBundle);
  const isResponsiveEditor = useSelector(getIsCreatedWithResponsiveEditor);
  const menuSettings = useBlogMenuSettings();
  const isFeedPageBundle = useSelector(getIsAppBundle);
  const isCategorySection = useSelector((state) => getSection(state) === SECTION_CATEGORY);

  useEffect(() => {
    if (!isEditor || isPreview) {
      document.documentElement.classList.add('enable-scroll');
    }
  }, [isPreview, isEditor]);

  const blogBackgroundColorClassName =
    isFeedPageBundle && isCategorySection
      ? 'blog-background-category-color'
      : 'blog-background-color';

  return (
    <AppWrapper
      className={classNames(
        className,
        styles.app,
        blogBackgroundColorClassName,
        isPostPageBundle && styles.postPageApp,
      )}
    >
      <TooltipPortalProvider>
        {messageRoot}
        {modalRoot}
        {popoverRoot}
        <div id="content-wrapper">
          {shouldShowHeader(desktopHeader) && React.cloneElement(desktopHeader, { menu })}
          {main && React.cloneElement(main, { children })}
        </div>
      </TooltipPortalProvider>
    </AppWrapper>
  );

  function shouldShowHeader(
    headerElement: React.FunctionComponentElement<any> | undefined,
  ): headerElement is React.FunctionComponentElement<any> {
    const settingsEnabled = isResponsiveEditor
      ? menuSettings.showCategoryLabels
      : menuSettings.showBlogMenu;

    if (settingsEnabled && headerElement) {
      return true;
    }

    return false;
  }
};

export default AppDesktop;
